<template>
  <b-container>
    <b-row>
      <b-col md="6" offset-md="3">
        <div class="text-center">
          <p class="great-text">
            Order Sucess <br />
            #{{ $route.params.orderId }}
          </p>
          <p class="success-msg-text">Successfully Booked Your Order</p>
          <img class="mb-3" src="img/checkout/order-success.png" alt="" />
          <!-- <button type="button" class="btn btn-outline-primary btn-lg mr-3">
      <span class="button-text"></span> Track Order
    </button> -->
          <button
            @click="$router.push('/')"
            type="button"
            class="btn btn-primary btn-lg"
          >
            <span class="button-text"></span> Continue Shopping
          </button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {};
</script>
<style scoped>
.success-msg-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  align-items: center;
}
.great-text {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
}
.btn-primary {
  width: 280px;
  height: 45px;
  border-radius: 3px;
}
.button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #faa819;
}
.button-text :hover {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #fff;
}
</style>
