var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "offset-md": "3"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "great-text"
  }, [_vm._v(" Order Sucess "), _c('br'), _vm._v(" #" + _vm._s(_vm.$route.params.orderId) + " ")]), _c('p', {
    staticClass: "success-msg-text"
  }, [_vm._v("Successfully Booked Your Order")]), _c('img', {
    staticClass: "mb-3",
    attrs: {
      "src": "img/checkout/order-success.png",
      "alt": ""
    }
  }), _c('button', {
    staticClass: "btn btn-primary btn-lg",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/');
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }), _vm._v(" Continue Shopping ")])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }